import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/circleci/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Banner, Stack } from '@patreon/studio';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "migrating-more-complicated-strings"
    }}>{`Migrating more complicated strings`}</h1>
    <p>{`Strings that include some runtime logic are more difficult to translate. Since
we need to convert messages to be `}<a parentName="p" {...{
        "href": "/how-to"
      }}>{`statically known`}</a>{` these types of
strings will likely take the bulk of our time.`}</p>
    <h2 {...{
      "id": "jsx"
    }}>{`JSX`}</h2>
    <h3 {...{
      "id": "if-statements-and-ternaries"
    }}>{`If statements and ternaries`}</h3>
    <p>{`Ternaries are very common in React code. So let's start with an example that
isn't internationally friendly.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`let buttonCopy
if (isCampaignConnected) {
    buttonCopy = 'Disconnect'
} else {
    buttonCopy = isCampaignSetup ? 'Complete Setup' : 'Connect'
}
return <Button>{buttonCopy} Now</Button>
`}</code></pre>
    <Banner inline={true} variant={Banner.Variant.Warning} mdxType="Banner">
    <b>Warning:</b> Do not do this. This is an example of something you could
    do, but is not the right way to approach this problem.
    </Banner>
    <br />
    <p>{`Here we are trying to display some text to a user to help them know the status
of their campaign. If we were to translate this in the simplest way we might get
something like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`let buttonCopy
if (isCampaignConnected) {
    buttonCopy = translate(locale, 'Disconnect')
} else {
    buttonCopy = isCampaignSetup
        ? translate(locale, 'Complete Setup')
        : translate(locale, 'Connect')
}
return <Button intl={locale}>{buttonCopy} Now</Button>
`}</code></pre>
    <p>{`This sends the following messages to your translator:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
    "Disconnect": "Disconnect",
    "Complete Setup": "Complete Setup",
    "Connect": "Connect",
    "{buttonCopy} Now": "{buttonCopy} Now"
}
`}</code></pre>
    <p>{`Unfortunately, we have just introduced a bug! We are `}<em parentName="p">{`sneakily`}</em>{` embedding
messages inside messages. Which as `}<a parentName="p" {...{
        "href": "/how-to"
      }}>{`we learned earlier`}</a>{` are not
statically known strings. This can produce mistranslations in the future. Also
the mesage "{buttonCopy} now" isn't incredibly meaningful.`}</p>
    <p>{`Instead let's try a few different approaches, all of which are valid and up to
you!`}</p>
    <h3 {...{
      "id": "using-select"
    }}>{`Using Select`}</h3>
    <p>{`If your booleans can easily be converted to strings, this is probably the best
approach that gives your translators the most context. It would look something
like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`return (
    <Button intl={locale}>
        <Select
            value={campaignState}
            matches={{
                connected: 'Disconnect',
                setup: 'Complete Setup',
                other: 'Connect',
            }}
        />{' '}
        Now
    </Button>
)
`}</code></pre>
    <p>{`This produces the message:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
    "{campaignState, select, connected {Disconnect} setup {Complete Setup} other {Connect}} Now": "{campaignState, select, connected {Disconnect} setup {Complete Setup} other {Connect}} Now"
}
`}</code></pre>
    <p>{`Which, while much longer, gives the translator much more control and context
over the entire string. Also, in my opinion, it's easier for the developer to
see what the full messages are and how to reproduce them. Not to mention fewer
words for your translator to translate.`}</p>
    <h3 {...{
      "id": "multiple-returns"
    }}>{`Multiple returns`}</h3>
    <p>{`The other way to handle this would be to have multiple returns with the full
strings in them`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`if (isCampaignConnected) {
    return <Button intl={locale}>Disconnect Now</Button>
} else {
    return isCampaignSetup ? (
        <Button intl={locale}>Complete Setup Now</Button>
    ) : (
        <Button intl={locale}>Connect Now</Button>
    )
}
`}</code></pre>
    <p>{`This allows us to maintain our original code structure and provide the entire
string to our translators`}</p>
    <h2 {...{
      "id": "translate"
    }}>{`Translate`}</h2>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      